<template>
  <!-- 新規作成ボタン -->
  <a-button type="primary" @click="showDrawer">
    <template #icon>
      <PlusOutlined />
    </template>
    新規作成
  </a-button>

  <!-- フォーム -->
  <!-- 再利用されないためコンポーネント分割は見送り -->
  <a-drawer title="新規作成" :width="720" :visible="visible" :body-style="{ paddingBottom: '80px' }"
    :footer-style="{ textAlign: 'right' }" @close="onClose">

    <a-form layout="horizontal" autocomplete="off">
      <a-typography-text>①アップロード先フォルダ</a-typography-text>
      <a-form-item label=" " name="dataset_to"
        :rules="[{ required: true, message: 'アップロード先フォルダは必須項目です', validator: checkDatasetTo, trigger: 'change' }]">
        <!-- <a-input v-model:value="dataset_to" allowClear=true placeholder="(TODO:Cascaderに変更予定)" /> -->
        <UploadDestinationFolderCascader ref="upload_dest_folder_cascader"/>
      </a-form-item>

      <a-typography-text>②データ収集開始日</a-typography-text>
      <CollectionStartDatePicker ref="child_startdate_picker" />
      <a-typography-text>③向け先、④機種</a-typography-text><br>
      <a-form-item label=" " name="customerandmodel"
        :rules="[{ required: true, message: '向け先は必須項目です', validator: checkCustomer, trigger: 'change' }]">
        <!-- <CustomerAndModelChooser /> -->
        <CustomerAndModelCascader ref="child_customer_cascader" />
      </a-form-item>

      <a-typography-text>⑤目的・機能</a-typography-text>
      <a-form-item label=" " name="purpose"
        :rules="[{ required: true, message: '目的・機能は必須項目です', validator: checkPurpose, trigger: 'change' }]">
        <PurposeChooser ref="purpose_chooser"/>
      </a-form-item>

      <!-- 件名 -->
      <a-typography-text>⑥件名(②③④⑤より自動生成、編集も可(英数字と一部記号のみ使用可))</a-typography-text>
      <a-form-item label=" " name="title" :rules="[{ validator: checkTitle, trigger: 'change' }]">
        <a-input v-model:value="title" allowClear=true placeholder="自由入力" />
      </a-form-item>

      <!-- アップロードするデータセット -->
      <a-typography-text>⑦アップロードするファイル</a-typography-text>
      <a-form-item label=" " name="dataset_from" :rules="[{ required: true, message: 'アップロード元フォルダは必須項目です' }]">
        <div class="drop_area" @dragenter="dragEnter" @dragleave="dragLeave" @dragover.prevent @drop.prevent="dropFile"
          :class="{ enter: isEnter }">
          ドラッグ＆ドロップで指定
        </div>
        <ul class="flex">
          <li v-for="(file, index) in files" :key="index" class="flex-col" @click="deleteFile(index)">
            <div style="position: relative;">
              <span class="delete-mark">×</span>
              <img class="file_icon" :src="getIcon(file)">
            </div>
            <span>
              {{ file.name }}
            </span>
          </li>
        </ul>
      </a-form-item>

      <a-typography-text>⑧旧パス</a-typography-text>
      <a-form-item label=" " name="path">
        <!-- 旧パス -->
        <a-input v-model:value="old_path" allowClear=true placeholder="自由入力" />
      </a-form-item>

    </a-form>

    <template #extra>
      <a-space direction="horizontal" style="width: 100%">
        <a-button @click="onClose">キャンセル</a-button>
        <div v-if="isValid">
          <a-button type="primary" :loading="isLoading" @click="onCreate">作成</a-button>
        </div>
        <div v-else>
          <a-button disabled>作成</a-button>
        </div>
      </a-space>
    </template>

  </a-drawer>
</template>

<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, provide, inject } from 'vue';
import { notification } from 'ant-design-vue';
import CollectionStartDatePicker from '@/components/parts/CollectionStartDatePicker.vue'
// import CustomerAndModelChooser from '@/components/parts/CustomerAndModelChooser.vue';
import CustomerAndModelCascader from '@/components/parts/CustomerAndModelCascader.vue';
import PurposeChooser from '@/components/parts/PurposeChooser.vue';
import UploadDestinationFolderCascader from '@/components/parts/UploadDestinationFolderCascader.vue';
// import { UploadFile, UploadRequest } from '@/protobuf/UploadRequest_pb'
// import { useGetFilenames } from '@/composable/useGetFilenames'
// import { message } from 'ant-design-vue';
import useInsertRecord from '@/composable/useInsertRecord';
import useUploadDataset from '@/composable/useUploadDataset'

export default defineComponent({
  components: {
    PlusOutlined,
    CollectionStartDatePicker,
    // CustomerAndModelChooser,
    CustomerAndModelCascader,
    PurposeChooser,
    UploadDestinationFolderCascader,
  },
  setup() {
    // 下位コンポーネントとの共有用変数
    const start_date = ref(null)
    const end_date = ref(null)
    const customer = ref(null)
    const model = ref(null)
    const purpose = ref(null)
    const old_path = ref(null)
    const title = ref(null)
    // const dataset_from = ref(null)
    const dataset_to = ref(null)

    // 上位コンポーネントから共有された変数
    const username = inject('username')
    const id_token = inject('id_token')

    provide('start_date', start_date)
    provide('end_date', end_date)
    provide('customer', customer)
    provide('model', model)
    provide('purpose', purpose)
    provide('old_path', old_path)
    provide('title', title)
    // provide('dataset_from', dataset_from)
    provide('dataset_to', dataset_to)

    const isLoading = ref(false);
    const visible = ref(false);
    const showDrawer = () => {
      visible.value = true;
      isLoading.value = false;
    };
    const onClose = () => {
      visible.value = false;
      isLoading.value = true;
    };

    const notifyCreateSuccess = () => {
      notification.open({
        message: '登録に成功しました',
        duration: 3,
      });
    };
    const notifyGeneralError = () => {
      notification.open({
        message: 'エラーが発生しました',
        //description : error //ポップアップが消えなくなるため削除
        duration: 10,  // 自動消去しない
        style: {
          width: '600px',
          marginLeft: `${335 - 600}px`,
          color: '#FF0000',
        },
      });
    };

    // 未入力チェック(ダミー)
    let checkEmpty = async (_rule, value) => {
      if (value) {
        // NOP、ダミーチェック
      }

      return Promise.resolve();
    };

    let checkCustomer = async (_rule, value) => {
      if (value) {
        // NOP、ダミーチェック
      }

      if (customer.value) {
        return Promise.resolve();
      }

      return Promise.reject('向け先は必須項目です');
    };

    let checkPurpose = async (_rule, value) => {
      if (value) {
        // NOP、ダミーチェック
      }

      if (purpose.value) {
        return Promise.resolve();
      }

      return Promise.reject('目的・機能は必須項目です');
    };

    let checkDatasetTo = async (_rule, value) => {
      if (value) {
        // NOP、ダミーチェック
      }

      if (dataset_to.value) {
        return Promise.resolve();
      }

      return Promise.reject('アップロード先フォルダは必須項目です');
    };

    let checkTitle = async (_rule, value) => {
      if (value) {
        // NOP、ダミーチェック
      }

      if (title.value.match(/[\\/:*?"<>|]/)) {
        return Promise.reject('件名は英数字およびファイル名に使用できる記号で入力してください');
      }

      if (title.value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)) {
        return Promise.resolve();
      }

      return Promise.reject('件名は英数字およびファイル名に使用できる記号で入力してください');
    };
    return {
      username,
      id_token,
      isLoading,

      visible,
      showDrawer,
      onClose,

      notifyCreateSuccess,
      notifyGeneralError,

      checkEmpty,
      checkCustomer,
      checkPurpose,
      checkDatasetTo,
      checkTitle,

      start_date,
      end_date,
      customer,
      model,
      purpose,
      old_path,
      title,
      // dataset_from,
      dataset_to,
    };
  },
  methods: {
    onCreate() {
      this.isLoading = true
      const start_date = this.start_date;
      const customer = this.customer;
      const model = this.model;
      const purpose = this.purpose;
      const old_path = this.old_path;
      const dataset_to = this.dataset_to;
      const title = this.title;
      const username = this.username;
      const id_token = this.id_token;
      const files = this.files;
      alert('作成中は画面を操作しないでください。\nまた、作成中に変更したパラメータは結果に反映されません。')
      useInsertRecord(
        start_date,
        customer,
        model,
        purpose,
        old_path,
        dataset_to,
        title,
        username,
        id_token
      )
      .then(() => {
        useUploadDataset(
          dataset_to,
          title,
          files,
        )
        .then(() => {
          this.notifyCreateSuccess();
          this.loading = false;
          this.visible = false;
        })
        .catch(() => {//引数errorは削除
          this.notifyGeneralError();
          this.loading = false;
          this.visible = false;
        });
      })
      .catch((err) => {
        console.log(err);
        if (err?.response?.status === 401) {
          alert("リロードしてください(トークンの有効期限切れ)");
        } else {
          this.notifyGeneralError();
        }
        this.loading = false;
        this.visible = false;
      })
    },
    onRequiredItemUpdated() {
      // 必須項目が全て入力済みか？
      // (書式不正は考慮しない)
      this.isValid = (this.customer && this.purpose && this.start_date && this.dataset_to && this.files.length > 0)
    },
    onTitleUpdated() {
      this.title = ''

      if (this.start_date) {
        this.title = this.start_date
      }

      if (this.customer) {
        this.title += "_" + this.customer
      }

      if (this.model) {
        this.title += "_" + this.model
      }

      if (this.purpose) {
        this.title += "_" + this.purpose
      }
    },
    dragEnter() {
      this.isEnter = true;
    },
    dragLeave() {
      this.isEnter = false;
    },
    dropFile() {
      this.files.push(...Array.from(event.dataTransfer.items).map(item => item.webkitGetAsEntry()));
      this.isEnter = false;

      // watchでfilesの増減を検知できないため明示的に呼び出す
      this.onRequiredItemUpdated()
    },
    deleteFile(index) {
      this.files.splice(index, 1)

      // watchでfilesの増減を検知できないため明示的に呼び出す
      this.onRequiredItemUpdated()
    },
    clearItems() {
      this.$refs.upload_dest_folder_cascader.clear();
      this.$refs.child_startdate_picker.clear();
      this.$refs.child_customer_cascader.clear();
      this.$refs.purpose_chooser.clear();
      this.title = '';
      this.files = [];
      this.old_path = '';
    },
    getIcon(file) {
      return file.isDirectory ? 'img/icon-directory.png' : 'img/icon-file.png';
    },
  },
  data() {
    return {
      isValid: false,   // 必須項目が入力済みか
      isEnter: false,   // アップロードするファイルがD&Dされたか
      files: [],        // アップロードするファイル
    }
  },
  watch: {
    // 入力必須項目 and/or 件名構成要素の監視
    "start_date": function () {
      this.onRequiredItemUpdated();
      this.onTitleUpdated();
    },
    "customer": function () {
      this.onRequiredItemUpdated();
      this.onTitleUpdated();
    },
    "purpose": function () {
      this.onRequiredItemUpdated();
      this.onTitleUpdated();
    },
    "title": function () {
      this.onRequiredItemUpdated();
    },
    "dataset_to": function () {
      this.onRequiredItemUpdated();
    },
    "model": function () {
      this.onTitleUpdated();
    },
  },
  updated() {
    // TODO: 子コンポーネントの入力状態の初期化
    // Drawerの表示状態変化時に子コンポーネントのライフサイクルハックAPIが呼び出されないため
    // 親コンポーネントから明示的に初期化関数を呼び出す
    // (beforeUpdatedではエラーになった)
    // console.log('DBG:beforeUpdate')
    this.clearItems();
  },
});
</script>

<style scoped>
.drop_area {
  color: gray;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 100px;
  border: 5px solid gray;
  border-radius: 15px;
}

.enter {
  border: 10px dotted powderblue;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  font-size: 10px;
}

.delete-mark {
  position: absolute;
  top: -14px;
  right: -10px;
  font-size: 20px;
}
</style>
  