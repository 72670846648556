import { Storage } from 'aws-amplify';

async function useUploadDataset(upload_to, title, entries,) {
    const fileEntries = [];
    await Promise.all(
        entries.map(entry => scanFiles(entry, fileEntries))
    );

    return Promise.all(
        Array.from(fileEntries).map(
            (fileEntry) => new Promise((resolve, reject) => {fileEntry.file(file => {
                const reader = new FileReader();
                reader.readAsArrayBuffer(file);

                reader.onload = () => {
                    let filePath = upload_to + "/" + title + fileEntry.fullPath;

                    console.log('useUploadDataset:', filePath)

                    Storage.put(filePath, file,
                        {
                            progressCallback(progress) {
                                console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                            },
                        }
                    )
                        .then(result => {
                            console.log(result);
                            resolve(result);
                        })
                        .catch(err => {
                            console.log(err);
                            reject(err);
                        });
                };
            })})
        )
    );

}


async function scanFiles(entry, tmpObject) {
    switch (true) {
        case (entry.isDirectory) : {
            console.log("useUploadDataset() check1-1");
            const entryReader = entry.createReader();
            const entries = await new Promise(resolve => {
                entryReader.readEntries(entries => resolve(entries));
            });
            await Promise.all(entries.map(entry => scanFiles(entry, tmpObject)));
            break;
        }
        case (entry.isFile) : {
            tmpObject.push(entry);
            break;
        }
    }
}

export default useUploadDataset;